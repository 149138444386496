import { useState, useRef } from 'react';

const useLoadMore = (
    initialPage = 1,
    initialNumItemsShown = 100
) => {
    const [loadMore, setLoadMore] = useState(false);
    const [page, setPage] = useState(initialPage);
    const [numItemShown, setNumItemsShown] = useState(initialNumItemsShown);
    const reachedEndRef = useRef(false);

    const loadMoreCards = () => {
        const newPage = page + 1;
        const numToShowNew = numItemShown * newPage;
        console.log('DEBUG: loadMoreCards ', newPage, numToShowNew);
        setLoadMore(true);
        setPage(newPage);
        setNumItemsShown(numToShowNew);
        reachedEndRef.current = true;
    };

    // Return the state and function so they can be used by the component
    return {
        loadMore,
        page,
        numItemShown,
        loadMoreCards,
        reachedEndRef
    };
}

export default useLoadMore;