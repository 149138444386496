import React, { useCallback, useEffect, useRef, useState, Suspense } from "react"

import { dataStore, locationStore, mapStore, pageStore } from "state/store-zustand";
import useContextTheme from 'components/utils/useContextTheme'
import usePageLoading from 'components/utils/usePageLoading'
import useIFrameResizer from "components/utils/useIFrameResizer"
import useFetchBoundary from 'components/utils/useFetchBoundary.js'

import Header from "components/header"
import PlaceCards from 'components/elements/cards/placeCards'
const Filters = React.lazy(() => import('components/filters/Filters'))

import FetchPlaces from 'components/utils/fetchPlaces'
import useLoadMore from 'components/utils/loadUtils'
import { handlePlaceRoute } from "components/utils/routeUtils"

import "styles/pages.scss"
//import "styles/map_page.scss"

const Directory = props => {
  let {
    slug,
    location
  } = props

  const {
    setBoundaries,
    showBoundary,
  } = mapStore((state) => state);

  const {
    cardLayout,
    cardOr,
    compact,
    embedded,
    height,
    isMobile,
    setCardOr,
    setHeight,
  } = pageStore((state) => state)

  const cityCurrent = locationStore((state) => state.cityCurrent)
  const { boundaries, error } = useFetchBoundary(showBoundary, cityCurrent, setBoundaries);

  const slugFromPath = location.pathname.split('/')[2]
  const placeSlug = slug || slugFromPath && slugFromPath != "" ? slugFromPath : null

  // TODO: OR like this?
  const pageLoader = usePageLoading()

  const containerRef = useRef(null)
  const [hasResizer, parentIFrame] = useIFrameResizer({ containerRef: containerRef })
  console.log('DEBUG hasResizer: ', hasResizer);
  if (hasResizer) {
    window.parentIframe = parentIFrame
  }

  const themeContext = useContextTheme()
  const { theme, themeClass, themeName } = themeContext

  // Map has some state for directory and map
  const {
    showAddress,
    showDescription,
    setShowAddress,
  } = mapStore((state) => state);

  useEffect(() => {
    setShowAddress(true)
    setCardOr('vertical')
  }, [])


  const {
    hasPlace,
    numPlacesShown,
    places,
    placeCurrent,
    setHasPlace,
    setPlaceCurrent,
    setNumPlacesToShow,
  } = dataStore((state) => state)

  const showLoadMore = numPlacesShown < places.length && cardLayout !== 'carousel'
  const showSearch = cardLayout != 'carousel'

  const fetchPlaces = true
  const containerClass = hasResizer
    ? `container container-resizable`
    : `container`

  const pageClass = `page map ${pageLoader} ${embedded ? 'embedded' : ''} ${compact ? 'compact' : ''} ${themeClass ? themeClass : ''}`

  const {
    loadMore,
    page,
    numItemShown,
    loadMoreCards,
    reachedEndRef
  } = useLoadMore(1, numPlacesShown);
  //console.log('DEBUG page num: ', page, numItemShown);


  const handleCardClick = useCallback((slug, place) => {
    const isTitleCard = place?.properties?.is_title_card

    handlePlaceRoute({
      place: place,
      leaveMap: true,
      shouldReplace: true,
      location: location,
      theme: themeName,
      isTitleCard: isTitleCard
    })

    setPlaceCurrent(place)
    setHasPlace(true)
  }, [setPlaceCurrent]);


  const handleCardVisible = useCallback((slug, place) => {
    const index = places.findIndex(p => p.properties.slug == slug)

    const hasMore = places.length > numItemShown
    const isLast = index > 0 && index > places.length - 3 // load more when 3 cards from end
    const shouldLoadMore = hasMore && isLast
    //console.log('DEBUG: handleCardVisible ', slug, index, shouldLoadMore);
    if (shouldLoadMore) {
      loadMoreCards()
    }

  } , [places]);


  return (
    <div className={pageClass}>

      {fetchPlaces
        ? <FetchPlaces
            requireMapReady={false}
            page={page}
            per_page={numItemShown} />
        : null
      }
      <main className={containerClass} ref={containerRef} style={{ margin: '0 auto'}}>
        {embedded != true
          ? <Header />
          : null
        }

        <Suspense fallback={null}>
          <Filters
            page={'activities'}
            position={isMobile ? 'fixed' : 'relative'}
            size={'small'}
            showActivities={true}
            showCities={false}
            showDates={false}
            showSearch={showSearch}
            showScrollArea={isMobile}
            showVibes={true} />
        </Suspense>

        <PlaceCards
          listStyle={cardLayout}
          cardOr={cardOr}
          columns={3}
          //headerHeight={headerHeight}
          onClick={handleCardClick}
          onVisible={handleCardVisible}
          //onFocus={handleCardFocus}
          //onHover={handleCardHover}
          //onScroll={handleScroll}
          overflowStyle={'auto'}
          showAddress={showAddress}
          showDescription={showDescription}
          //showNote={showNote}
          showShadow={false}
          showLoadMore={showLoadMore} />

      </main>
    </div>
  )
}

export default Directory